import './MySubscriptionDetails.scss';
import React, { useEffect, useState } from 'react';
import { LegacyByzzerButton } from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useUser } from '@/contexts/UserContext';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import classnames from 'classnames';
import { useBetterNavigate } from '@/utils';
import { Tag } from 'antd';
import { ByzzerDate } from '@byzzer/ui-components';
import { add } from 'date-fns';
import {useSuiteCommerceLink} from "@/components/SuiteCommerceSSO";
import { CONTACT_SALES_REPRESENTATIVE_MESSAGE } from '@/constants/subscription.constants';

const baseClassName = 'my-subscription-details';
const MySubscriptionDetails = ({ className }) => {
    const {getMySubscriptionUsage } = useTenantApi();
    const {
        categoriesEditable,
        canEditCategories,
        isCsrObo: isObo,
        oboRole,
        user,
        categories,
        omniCategories,
        omniShopperCategories,
        subscription,
        admins,
        features,
        company,
        preSales
    } = useUser();

    const {openInNewTab, openPathInCurrentTab} = useSuiteCommerceLink();
    const [loading, setLoading] = useState(true);
    const [packageType, setPackageType] = useState('');
    const [unusedCategoryCnt, setUnusedCategoryCnt] = useState(0);
    const [totalCategories, setTotalCategories] = useState(0);
    const navigate = useBetterNavigate();
    const canManage = user?.role === 'admin';
    const isSalesObo = isObo && oboRole === 'sales'
    const categoriesLocked = isSalesObo || (
        (!canManage || !categoriesEditable) &&
        import.meta.env.REACT_APP_SUBSCRIPTION_CATEGORIES_ALWAYS_EDITABLE !== 'true'
    );
    const omniShopperCategoriesLocked = true; // todo: incorporate this into the backend using business logic.  For MVP, this is a placeholder.  Osh categories are not editable in the dashboard
    // Added for free user experience, BYZ-10746 Category is not editable for free Group B user
    const isCategoryEditable = preSales && company?.features?.freeAccountGroup === 'B' ? false : true;
    useEffect(() => {
        getAllData();
    }, []);

    const getAllData = async () => {
        try {
            setLoading(true);

            if (subscription) {
                const { title } = subscription;
                setPackageType(title);
                const usage = await getMySubscriptionUsage();
                setTotalCategories(usage.categories?.limit);
                setUnusedCategoryCnt(usage.categories?.limit - categories?.length);
                //research usage endpoint and see if it's providing correct count of "used" categories(currently shows '0')
            }
        } catch (err) {
            // todo: need to display something to the user
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    function editCategories() {
        let path = '/dashboard/my_categories';
        navigate(path, {
            state: {
                canEditCategories: canEditCategories,
                uneditableCategories: !canEditCategories ? categories : [],
            },
        });
    }

    const viewPaymentDetails = async () => {
        return openPathInCurrentTab('/sca-dev-2020-1/my_account.ssp?fragment=overview#overview');
    };
    return (
        <div className={classnames(baseClassName, className)}>
            <ByzzerMask show={loading} loading={loading} />
            {!loading && (
                <>
                    <div className={`${baseClassName}__plan`}>
                        <div className={`${baseClassName}__plan-details`}>
                            <h2 className={`${baseClassName}__title`}>{packageType ?? 'No Active Subscription'}</h2>
                            <div className={`${baseClassName}__notes`}>
                                {canManage && (
                                    <>
                                        <p>{CONTACT_SALES_REPRESENTATIVE_MESSAGE}</p>
                                        {/* NOTE:: Updating this below date to the previous implementation -> pointing to subscription end date
                                            for release-2023.09 */}
                                        {Boolean(subscription?.endDate) && (
                                            <p className={`${baseClassName}__expiration`}>
                                                Contract valid through: {' '}
                                                <ByzzerDate
                                                    // date={add(new Date(subscription?.endDate), {'days': 1})} // could do new Date(endDate.replace("Z","")) as well and then wouldnt have to add back a day
                                                    date={new Date(subscription?.endDate)} //removing add method as end date is getting shifted by 1 day from original date in netsuite
                                                    format={`M/d/yyyy '11:59 PM EST'`}
                                                    timeZone={'America/New_York'}
                                                />
                                            </p>
                                        )}
                                    </>
                                )}
                                {!canManage && (
                                    <>
                                        <p>You must be an admin to make changes to your subscription.</p>
                                        <p>
                                            Please contact your company's admin or customer support if you need
                                            additional assistance.
                                        </p>
                                    </>
                                )}
                            </div>
                            <div className={`${baseClassName}__admins`}>
                                <p className={`${baseClassName}__admins-role `}>{` ${admins?.length ? admins[0]?.role + ` - ` : ''} `} </p>
                                {admins?.map((admin, index) => (
                                    <p key={admin.id}>
                                        <span>{` ${admin.firstName} `}</span>
                                        <span>{` ${admin.lastName} `}</span>
                                        <span><a className='byzzer-link' href={`mailto:${admin.email}`}>{` ( ${admin.email} ) `}</a></span>
                                    </p>

                                ))}
                            </div>
                        </div>
                        <div className={`${baseClassName}__actions`}>
                            <LegacyByzzerButton disabled={canManage ? isObo : true} onClick={() => viewPaymentDetails()}>
                                Payment Details & Invoices
                            </LegacyByzzerButton>
                        </div>
                    </div>
                    <div className={`${baseClassName}__categories-details`}>
                        <h2
                            className={classnames(`${baseClassName}__title`, {
                                [`${baseClassName}__title--locked`]: categoriesLocked,
                            })}
                        >
                            Subscription Categories
                        </h2>
                        {canManage && !categoriesLocked && unusedCategoryCnt > 0 && (
                            <div className={`${baseClassName}__notes`}>
                                Your account includes access to {totalCategories} categories. Click "Edit Categories" to
                                change your subscription categories or add up to {unusedCategoryCnt} more.
                            </div>
                        )}
                        {canManage && !categoriesLocked && unusedCategoryCnt < 1 && (
                            <div className={`${baseClassName}__notes`}>
                                Your account includes access to {totalCategories} categories. Click "Edit Categories" to
                                change your subscription categories.
                            </div>
                        )}
                        {canManage && categoriesLocked && unusedCategoryCnt > 0 && (
                            <div className={`${baseClassName}__notes`}>
                                Your account includes access to {totalCategories} categories. Click "Edit Categories" to
                                add up to {unusedCategoryCnt} more. To change your current categories, contact Byzzer
                                support.
                            </div>
                        )}
                        {canManage && categoriesLocked && !categoriesEditable && unusedCategoryCnt < 1 && (
                            <div className={`${baseClassName}__notes`}>
                                Your account includes access to {totalCategories} categories. To change the categories
                                or add more, contact Byzzer support.
                            </div>
                        )}
                        <div className={`${baseClassName}__categories`}>
                            {categories.map((category, index) => (
                                <Tag
                                    key={category}
                                    className={classnames(`${baseClassName}__category`, {
                                        [`${baseClassName}__category--locked`]: categoriesLocked,
                                    })}
                                >
                                    <div>{category}</div>
                                </Tag>
                            ))}
                            {!categories.length && (
                                <div className={`${baseClassName}__category-placeholder`}>
                                    You have not selected any categories.
                                </div>
                            )}
                        </div>
                        <div className={`${baseClassName}__categories-actions`}>
                            {/* {!categoriesLocked && (
                            <ByzzerButton onClick={editCategories}>Edit Categories</ByzzerButton>
                        )} */}

                            {((canManage && !categoriesLocked) ||
                                (canManage && categoriesLocked && !isSalesObo && unusedCategoryCnt > 0)) && (
                                    <LegacyByzzerButton onClick={editCategories} disabled={!isCategoryEditable}>Edit Categories</LegacyByzzerButton>
                                )}
                        </div>
                    </div>
                    <hr />

                    {(features?.enableOmniAccess) && (

                        <div className={`${baseClassName}__categories-details`}>
                            <h2
                                className={classnames(`${baseClassName}__title`, {
                                    [`${baseClassName}__title--locked`]: categoriesLocked,
                                })}
                            >
                                Omni Sales Subscription Categories
                            </h2>
                            {Boolean(omniCategories?.length) && (
                                <div className={`${baseClassName}__notes`}>
                                    Your account includes access to Omni Sales data and reports for{' '}
                                    {omniCategories?.length} categories. To change the categories or purchase more,
                                    contact Byzzer support.
                                </div>
                            )}

                            <div className={`${baseClassName}__categories`}>
                                {omniCategories?.map((category, index) => (
                                    <Tag key={category} className={classnames(`${baseClassName}__category`)}>
                                        <div>{category}</div>
                                    </Tag>
                                ))}
                                {!omniCategories?.length && (
                                    <div className={`${baseClassName}__category-placeholder`}>
                                        You have not selected any Omni categories.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {(features?.enableOmniShopperAccess || features?.enableOmnishopperAccess) && ( // todo- check spelling of omnishopper.  capitalize 's' or not?
                        <div className={`${baseClassName}__categories-details`}>
                            <h2
                                className={classnames(`${baseClassName}__title`, {
                                    [`${baseClassName}__title--locked`]: omniShopperCategoriesLocked && omniShopperCategories?.length,
                                })}
                            >
                                Omnishopper Subscription Categories
                            </h2>
                            {Boolean(omniShopperCategories?.length) && (
                                <div className={`${baseClassName}__notes`}>
                                    Your account includes access to Omnishopper data and reports for {' '}
                                    {omniShopperCategories?.length} categories. To change the categories or purchase more,
                                    contact Byzzer support.
                                </div>
                            )}

                            <div className={`${baseClassName}__categories`}>
                                {omniShopperCategories?.map((category, index) => (
                                    <Tag key={category} className={classnames(`${baseClassName}__category`)}>
                                        <div>{category}</div>
                                    </Tag>
                                ))}
                                {!omniShopperCategories?.length && (
                                    <div className={`${baseClassName}__category-placeholder`}>
                                        You have not selected any Omnishopper categories.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MySubscriptionDetails;

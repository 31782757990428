import React, { forwardRef, ReactNode, useEffect, useRef, useState } from 'react';
import { ByzzerChangeEventHandler, ByzzerSelectOption, WithValue, ByzzerSelect } from '@byzzer/ui-components';
import classnames from 'classnames';
import { useApp } from '@/contexts/UserContext';
import { DemographicDimension } from '@/types/ReportRun';
import { SelectorLabelInfo } from '../SelectorLabelInfo/SelectorLabelInfo';
import { useTenantApi } from '@/hooks';

const baseClassName = 'byz-osh-demographics-dimension-select';

export type ByzzerOshDemographicDimensionSelectProps = {
    value?: (string | DemographicDimension)[];
    className?: string;
    name?: string;
    label?: ReactNode;
    onChange?: ByzzerChangeEventHandler<DemographicDimension[]>;
    placeholder?: string;
    maxSelections?: number;
    disabled?: boolean;
    disabledOptions?: string[] | ByzzerSelectOption[];
    sku?: string;
    required?: boolean;
} & OnlyRenderableIf;

export const ByzzerOshDemographicDimensionSelect = forwardRef<
    WithValue<DemographicDimension[]>,
    ByzzerOshDemographicDimensionSelectProps
>(
    (
        {
            className,
            label,
            name,
            onChange,
            value,
            placeholder,
            maxSelections,
            disabled,
            onlyRenderIf = true,
            disabledOptions,
            sku,
            required,
            ...props
        },
        ref
    ) => {
        if (onlyRenderIf === false) return <></>;

        const { getOshDemographics } = useTenantApi();
        const [demographicOptions, setDemographicOptions] = useState<string[]>([]);
        const [internalValue, setInternalValue] = useState<DemographicDimension[]>([]);

        const DEMOGRAPHIC_DIM_LABEL_AND_TIPINFO = (
            <>
                {' '}
                <p>
                    <b>Choose Demographics to report.</b>
                </p>
                <span>
                    This report includes an analysis on share of shoppers within different demographic segments.{' '}
                    {maxSelections
                        ? `Choose up to ${maxSelections} demographics broken out by segment in this report.`
                        : undefined}
                </span>
            </>
        );

        useEffect(() => {
            (async () => {
                try {
                    const resp = await getOshDemographics();
                    setDemographicOptions(resp);
                } catch (error) {
                    console.log('error', error);
                }
            })();
        }, []);

        useEffect(() => {
            // changes after the initial load would be handled here
            setInternalValue((value as DemographicDimension[]) ?? []);
        }, [value]);

        const handleChange = (e: any) => {            
            if (onChange) {
                onChange?.({
                    name,
                    value: e.value, // e.data contains an array of display/value pairs so just using that for value
                    data: e.data,
                });
            } else {
                setInternalValue(e);
            }
        };

        return (
            <>
                {Number(sku) ? (
                    <SelectorLabelInfo
                        sku={sku as string}
                        selectorCode={'omniShopperDemographicDimensions'}
                        max={maxSelections}
                        isLabelBold={true}
                        required={required}
                    />
                ) : (
                    DEMOGRAPHIC_DIM_LABEL_AND_TIPINFO
                )}
                <ByzzerSelect
                    ref={ref}
                    name={name}
                    className={classnames(baseClassName, className)}
                    options={demographicOptions}
                    disabled={disabled}
                    placeholder={placeholder}
                    label={label}
                    value={internalValue}
                    disabledOptions={disabledOptions}
                    onChange={handleChange}
                    allowMultiple={true}
                    maxSelections={maxSelections}
                />
            </>
        );
    }
);

export default ByzzerOshDemographicDimensionSelect;

ByzzerOshDemographicDimensionSelect.displayName = 'ByzzerOshDemographicDimensionSelect';

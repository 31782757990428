import { forwardRef, useEffect, useState } from 'react';
import { FilterSection } from '../ConfigurationEditors/FilterGroup';
import { SelectorLabelInfo } from '../SelectorLabelInfo';
import { ReportRunConfig, RunConfigMarket } from '@/types/ReportRun';
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    ByzzerSelectOptionGroup,
} from '@byzzer/ui-components';
import { useTenantApi } from '@/hooks';
import classNames from 'classnames';
import { LABELS, OUTLETS_SELECTOR_NAME, TOTAL_MARKETS_SELECTOR_NAME } from './byzzerOshMarketSelect.constants';
import { isValidArray } from '@/utils';

type ByzzerOshMarketSelectProps = {
    sku: string;
    value: Partial<ReportRunConfig>;
    onChange?: ByzzerChangeEventHandler<RunConfigMarket[]>;
    isOshTotalMarketsDisabled?: boolean;
    isOshOutletsDisabled?: boolean;
    allowMultipleTotalMarkets?: boolean;
    allowMultipleOutlets?: boolean;
};

const baseClassName = 'byz-osh-market-select';
export const ByzzerOshMarketSelect = forwardRef<HTMLDivElement, ByzzerOshMarketSelectProps>(
    (
        {
            sku,
            value,
            onChange,
            isOshTotalMarketsDisabled = false,
            isOshOutletsDisabled = false,
            allowMultipleTotalMarkets = false,
            allowMultipleOutlets = false,
        },
        ref
    ) => {
            console.log("TCL ~ value:", value);
        const { getOmnishopperMarkets } = useTenantApi();
        const [options, setOptions] = useState<{
            totalMarkets: ByzzerSelectOption[];
            outlets: ByzzerSelectOptionGroup[];
        }>({
            totalMarkets: [],
            outlets: [],
        });

        useEffect(() => {
            (async () => {
                try {
                    const oshMarkets = await getOmnishopperMarkets();
                    const totalMarketOptions = oshMarkets.totalMarkets.map((name) => ({ display: name, value: name }));
                    const outletOptions = oshMarkets.outlets.map(({ label, options }) => ({
                      label,
                      options: options.map((name) => ({ display: name, value: name })),
                    }));
                    setOptions({
                        totalMarkets: totalMarketOptions,
                        outlets: outletOptions,
                    });
                } catch (error) {
                    console.error('Error fetching omnishopper markets');
                    setOptions({
                        totalMarkets: [],
                        outlets: [],
                    });
                } finally {
                }
            })();
        }, []);

        return (
            <div>
                <FilterSection onlyRenderIf={true}>
                    <SelectorLabelInfo
                        defaultLabel="Total Markets"
                        sku={sku as string}
                        selectorCode={'oshTotalMarkets'} //TODO: change selectorCode
                        // required={true}
                        isLabelBold={true}
                    />
                    <MarketSelector
                        className="byz-osh-total-markets"
                        name={TOTAL_MARKETS_SELECTOR_NAME}
                        options={options.totalMarkets}
                        disabled={isOshTotalMarketsDisabled || (value.subMarkets && value.subMarkets?.length > 0)}
                        placeholder="Select Total Market(s)"
                        allowMultiple={allowMultipleTotalMarkets}
                        onChange={onChange}
                        value={value.markets}
                    />
                </FilterSection>
                <FilterSection onlyRenderIf={true}>
                    <SelectorLabelInfo
                        defaultLabel="Outlets"
                        sku={sku as string}
                        selectorCode={'oshOutlets'} //TODO: change selectorCode
                        // required={true}
                        isLabelBold={true}
                    />
                    <MarketSelector
                        className="byz-osh-outlets"
                        name={OUTLETS_SELECTOR_NAME}
                        options={options.outlets}
                        disabled={isOshOutletsDisabled || (value.markets && value.markets?.length > 0)}
                        placeholder="Select Outlet(s)"
                        allowMultiple={allowMultipleOutlets}
                        onChange={onChange}
                        value={value.subMarkets}
                    />
                </FilterSection>
            </div>
        );
    }
);

const MarketSelector = ({
    className,
    name,
    options,
    disabled,
    allowMultiple,
    placeholder = 'Select Values',
    onChange,
    value
}) => {
    const defaultDisabledOption = [{ display: 'Disabled', value: 'disabled' }];
    const [internalValue, setInternalValue] = useState<ByzzerSelectOption[]>([]);
    const [disabledOptions, setDisabledOptions] = useState(defaultDisabledOption);

    const handleChange = (e: ByzzerChangeEvent<any>) => {
        setInternalValue(e.data);
        const value = e.data?.map((data) => ({
            name: data.value,
            key: data.value,
        }));
        onChange?.({
            name: name,
            data: e.data,
            value,
        });
    };

    useEffect(() => {
        if (allowMultiple && name === TOTAL_MARKETS_SELECTOR_NAME && internalValue.length > 0) {
            const isTotalOutletSelected = internalValue.some((data) => data.value === LABELS.TOTAL_OUTLETS);

            const disabledOptions = options.filter((opt) =>
                isTotalOutletSelected ? opt.value !== LABELS.TOTAL_OUTLETS : opt.value === LABELS.TOTAL_OUTLETS
            );
            setDisabledOptions(disabledOptions);
        } else if (!allowMultiple && internalValue.length > 0) {
            if (name === OUTLETS_SELECTOR_NAME) {
                const disabledOptions = options.map((opt) => ({
                    ...opt,
                    options: opt.options.filter((childOpt) => childOpt.value !== internalValue[0]?.value),
                }));
                setDisabledOptions(disabledOptions);
            } else if (name === TOTAL_MARKETS_SELECTOR_NAME) {
                const disabledOptions = options.filter((opt) => opt.value !== internalValue[0]?.value);
                setDisabledOptions(disabledOptions);
            }
        } else {
            setDisabledOptions(defaultDisabledOption);
        }
    }, [options, internalValue, name, allowMultiple]);

    useEffect(() => {
        if (value) {
            const selectedValue = isValidArray(value) ? value : [];
            setInternalValue(selectedValue.map((item) => ({ display: item.name, value: item.key })));
        }
    }, [value])

    return (
        <ByzzerSelect
            name={name}
            className={classNames(baseClassName, className)}
            options={options}
            disabled={disabled}
            placeholder={placeholder}
            value={internalValue}
            disabledOptions={disabledOptions}
            onChange={handleChange}
            allowMultiple={true}
        />
    );
};

import React, { useEffect, forwardRef, ReactNode, HTMLAttributes, useRef, createContext } from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import './ByzzerOshDemographicCriteriaBuilder.scss';
import { ByzzerSelectOption, ByzzerSelectOptionGroup, ByzzerChangeEvent, ByzzerButton } from '@byzzer/ui-components';
import { DemographicCondition } from '@/types/ReportRun';
import { clone } from 'lodash';
import { ByzzerOshDemographicConditionInput, ByzzerOshDemographicOption } from './ByzzerOshDemographicConditionInput';
import { WithUid } from '@/types/WithUid';
import { nanoid } from 'nanoid';

const BYZZER_OSH_BLANK_DEMOGRAPHIC_CONDITION: DemographicCondition = {
    demographic: '',
    condition: 'is',
    value: [],
    data: {},
};

type ByzzerOshDemographicCriteria = DemographicCondition[];

type ByzzerOshDemographicCriteriaContextValue = {
    demographics: ByzzerOshDemographicOption[] | string[];
    selectedDemographics: string[];
};

export const OshDemographicCriteriaContext = createContext<ByzzerOshDemographicCriteriaContextValue>({} as any);

export type ByzzerOshDemographicCriteriaBuilderProps<T = any> = {
    value?: ByzzerOshDemographicCriteria;
    actions?: ReactNode;
    label?: ReactNode;
    keyLabel?: ReactNode;
    keyPlaceholder?: string;
    keyOptions?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    // disabledKeys?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    onKeyChange?: (e: ByzzerChangeEvent<string | undefined | null>) => void; // JS Doc

    operationLabel?: ReactNode;
    operationPlaceholder?: string;
    operationOptions?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    disabledOperations?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    onOperationChange?: (e: ByzzerChangeEvent<string>) => void;

    valueLabel?: ReactNode;
    valuePlaceholder?: string;
    valueOptions?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    disabledValues?: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    maxAllowedValues?: number;
    onValueChange?: (e: ByzzerChangeEvent<string | string[]>) => void;

    onChange?: (e: ByzzerChangeEvent<ByzzerOshDemographicCriteria>) => void;
    name?: string;
    search?: (searchText: string) => Promise<T[]>;
    converter?: (value: T) => ByzzerSelectOption;
    searchableFields?: string[];
    onlyRenderIf?: boolean;
    className?: string;
    showLabelsOnAllRows?: boolean;
    joinText?: 'and' | 'or' | string;
    maxConditions?: number;
    minConditions?: number;
    fieldsToRenderAsLargeList?: string[];
    allowMultipleValues?: boolean;
} & Partial<Omit<HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'byz-osh-demographic-criteria-builder';

export const ByzzerOshDemographicCriteriaBuilder = ({
            value,
            actions,
            showLabelsOnAllRows = false,
            joinText,
            label,
            keyLabel = 'Demographic',
            keyPlaceholder = 'Select from the list',
            keyOptions,
            // disabledKeys,
            onKeyChange,
            operationLabel = 'Condition',
            operationPlaceholder,
            operationOptions = ['is'],
            disabledOperations,
            onOperationChange,
            valueLabel = 'Segment',
            valuePlaceholder,
            valueOptions,
            disabledValues,
            maxAllowedValues = 1,
            onValueChange,
            onChange,
            search,
            converter,
            onlyRenderIf,
            name = 'DemographicCriteriaBuilder',
            searchableFields,
            className,
            maxConditions,
            minConditions = 1, // added default value so 1 row is always available
            fieldsToRenderAsLargeList,
            ...props
}: ByzzerOshDemographicCriteriaBuilderProps) => {
        if (onlyRenderIf === false) return <></>; // needs to stay at top of component to successfully execute useeffect hook on 'value' below.

        const [conditions, setConditions] = useState<WithUid<DemographicCondition, string>[]>([]);
        const disabledKeys = useRef<string[]>([]);
        const initialValueLoaded = useRef<Boolean>(false);

        useEffect(() => {
            // TODO - create logic to map given value to conditions.  probably only needed when defaults are given or rendering for first time
            // only do this on load for given value list.  otherwise, changes will be handled in onchange to prevent rebuidling list every time.
            if (!initialValueLoaded.current) {
                if (value?.[0]?.demographic?.length) {
                    // some preset value(s) to load, either from a prior report run, user defaults, or coming back to this after unmounting
                    const conditionsWithUids = value?.map((condition) => ({
                        value: condition,
                        uid: nanoid(),
                    }));
                    setConditions(conditionsWithUids);
                    initialValueLoaded.current = true; // finished initial load script, set to true to prevent above from reoccuring
                } else {
                    // after mounting and after clicking radio button and no prior value to load, create a new default condition
                    setConditions([createDefaultCondition()]);
                }
            }
        }, [value]);

        useEffect(() => {
            disabledKeys.current = conditions?.map((condition) => condition.value.demographic ?? '');
        }, [conditions]);

        function updateStates(
            conditionsWithUids: WithUid<DemographicCondition, string>[],
            onChange?: (e: ByzzerChangeEvent<ByzzerOshDemographicCriteria>) => void
        ) {
            if (onChange) {
                // onChange should fire together with setConditions
                onChange({
                    name,
                    value: conditionsWithUids.map((condition) => condition.value), // send only condition value without uid
                });
            }
            // triggers render of conditions, which kicks off updates in children conditions elements
            setConditions(conditionsWithUids); // setConditions should fire together with onChange
        }

        function handleChange(e: ByzzerChangeEvent<DemographicCondition>, index) {
            // console.log("(1) DemographicCriteriaBuilder - handleChange fired - 'e' ===>> ", e)
            const conditionsWithUids = conditions.map((condition, i) => {
                if (condition.uid === index) {
                    return { value: e.value, uid: condition.uid };
                } else {
                    return condition;
                }
            });
            // since this is currently not 100% a controlled component, for performance/UX reasons, state update functions are combined as one function and should fire together and be treated as one until better solution is implemented
            updateStates(conditionsWithUids, onChange);
        }

        function add() {
            setConditions((currentConditions) => [...currentConditions, createDefaultCondition()]);
            // https://stackoverflow.com/questions/39549424/how-to-create-unique-keys-for-react-elements
        }

        function remove(demographic: string) {
            const filteredConditions = conditions.filter((condition, i) => demographic !== condition.uid);
            updateStates(filteredConditions, onChange);
        }

        function createDefaultCondition() {
            return {
                value: clone(BYZZER_OSH_BLANK_DEMOGRAPHIC_CONDITION),
                uid: nanoid(),
            };
        }

        return (
            <div className={classnames(baseClassName, className)} {...props}>
                {conditions.map(({ value, uid }, index) => (
                    <React.Fragment key={uid}>
                        {joinText && index > 0 && (
                            <div className={classnames(`${baseClassName}__label-text`)}>{joinText.toUpperCase()}</div>
                        )}
                        <ByzzerOshDemographicConditionInput
                            value={value}
                            includeLabels={showLabelsOnAllRows ? true : index === 0}
                            onChange={(e) => handleChange(e, uid)}
                            actions={
                                <>
                                    <ByzzerButton
                                        onClick={() => add()}
                                        disabled={(maxConditions ?? Infinity) <= conditions.length}
                                    >
                                        +
                                    </ByzzerButton>
                                    <ByzzerButton
                                        onClick={() => remove(uid)}
                                        disabled={minConditions >= conditions.length}
                                    >
                                        -
                                    </ByzzerButton>
                                </>
                            }
                            disabledKeys={disabledKeys.current}
                            name={name}
                            renderLargeList={fieldsToRenderAsLargeList?.includes(value?.demographic)}
                            operationOptions={operationOptions}
                            maxAllowedValues={maxAllowedValues}
                        />
                    </React.Fragment>
                ))}
            </div>
        );
};

export default ByzzerOshDemographicCriteriaBuilder;

ByzzerOshDemographicCriteriaBuilder.displayName = 'ByzzerOshDemographicCriteriaBuilder';

import {
    ReportRunConfigWizardContext
} from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';
import { MonthEndingTimePeriod, TimePeriod, WeekEndingTimePeriod } from "@/types/ReportRun";
import { ByzzerSelect } from "@byzzer/ui-components";
import classnames from "classnames";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";

export type ReportKPIConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<TimePeriod>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: TimePeriod;
    summary?: ReactNode;
    datatype?: 'omni' | 'rms' | 'cps' | 'osh';
    includeChartData?: boolean;    
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const preDefinedKPISelectors: string[] = ['Value (Dollar volume)', 'Units'];

const baseClassName = 'report-kpi-config-filters';

export function ReportKPIConfigFilters(
    {
        className,
        onChange,
        onValidityChange,
        name,
        datatype,
        value,
        includeChartData,        
        ...props
    }: ReportKPIConfigFiltersProps) {
    const {value: contextValue, onChange: onContextChange, sku} = useContext(ReportRunConfigWizardContext);


    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerSelect
                value={contextValue.chartDataConfigurationType}
                label={
                    <>
                        {/* Chart Data Configuration:
                    <ByzzerTipIcon
                        type={'info'}
                        tip={
                            <>
                                This selection will organize your data in the Base vs Incremental stacked
                                bar chart only
                            </>
                        }
                        tipLocation='top'
                    /> */}
                        <SelectorLabelInfo sku={sku as string} selectorCode={'chartData'} />
                    </>
                }
                placeholder="Select you KPI"
                onChange={() => {}}
                options={preDefinedKPISelectors}
            />
        </div>
    );
}

export default ReportKPIConfigFilters;

import './ReportRunConfigEditor.scss';
import React, { useEffect, useState } from 'react';
import { RelativeTimePeriod, ReportRunConfig, RunConfigMarket } from '@/types/ReportRun';
import { ByzzerButton, ByzzerTipIcon } from '@byzzer/ui-components';
import DashboardContent from '@/components/dashboard/DashboardContent';
import { showErrorModal } from '@/utils';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useTenantApi } from '@/hooks/useTenantApi';
import { alert as byzzerAlert } from '@/components/form/ByzzerModal';
import { getRunConfigOptionsBySku, getProductBySku } from '@/services/product.service';
import { ProductRunConfigOptions, RunConfigOptions } from '@/types/RunConfigOptions';
import { useUser } from '@/contexts/UserContext';
import { ReportRunConfigWizard } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard';
import { useReportRunService } from '@/services/reportRun.service';
import { categoryAppearsInGivenList } from '../AlertRunConfigEditor';
import { CreditUsage } from '@/types/ApiTypes';
import { openCreateReportScheduleModal, openModifyReportRescheduleModal, openModifyReportScheduleConfigModal } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ScheduleReportStep/ScheduleModal';

export type ReportRunConfigEditorProps = React.HTMLAttributes<HTMLDivElement> & {};

// I don't think I will need this long term
export type ReportConfigurationValue = {};

type ReportType = 'core' | 'smart';

const baseClassName = 'report-run-config-editor';

export function ReportRunConfigEditor({ className, ...props }: ReportRunConfigEditorProps) {
    const {
        createAdHicReportRun,
        createSubscriptionReportRun,
        createSubscriptionOshReportRun,
        getMySubscriptionUsage,
        getReportRunConfigById,
        getRunConfigOptionsByCreditId,
        createScheduleReport,
        getScheduledReportSeriesNames,
        getScheduledReportById,
        rescheduleReport,
        updateScheduledReportConfig
    } = useTenantApi();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { getDefaultRunConfigBySku, getReportRunConfig } = useReportRunService();
    const [report, setReport] = useState<any>();
    const {
        subscribedSkus,
        categories: subscriptionCategories,
        omniCategories: subscriptionOmniCategories,
        customCharacteristics: subscriptionCustomCharacteristics,
    } = useUser();
    const [runConfigOptions, setRunConfigOptions] = useState<RunConfigOptions[]>();
    const [defaultRunConfig, setDefaultRunConfig] = useState<Partial<ReportRunConfig>>({});
    const [reportType, setReportType] = useState<ReportType>('core');
    const [generating, setGenerating] = useState<boolean>(false);
    const [enableScheduleReport, setEnableScheduleReport] = useState<boolean>(false);
    const [basicReportsUsage, setBasicReportsUsage] = useState<CreditUsage>({ limit: 0, used: 0 });
    const [defaultReport, setDefaultReport] = useState<Partial<ReportRunConfig>>({});
    const [schedule, setSchedule] = useState<Schedule>({
        frequency: 'one_time',
        deliveryDay: 'tuesday',
        deliveryGroupType: 'me',
        deliverToUserIds: [],
        duration: 1,
        scheduleDates: [],
        startingTimePeriod: '',
        timePeriods: [],
    });
    const [initialSchedule, setInitialSchedule] = useState<Schedule>({
        frequency: 'one_time',
        deliveryDay: 'tuesday',
        deliveryGroupType: 'me',
        deliverToUserIds: [],
        duration: 1,
        scheduleDates: [],
        startingTimePeriod: '',
        timePeriods: [],
    });
    const [scheduledSeriesNames, setScheduledSeriesNames] = useState<string[]>([]);
    const [scheduledRunConfig, setScheduledRunConfig] = useState<ReportRunConfig | undefined>(undefined);

    const runType: RunType = (searchParams.get('runType') as RunType) ?? 'subscription';
    const sku = searchParams.get('sku');
    const reportRunId = searchParams.get('reportId');
    const runCreditId = searchParams.get('creditId');
    const scheduleId = searchParams.get('scheduleId');
    const scheduleSeriesId = searchParams.get('scheduleSeriesId');
    const scheduleMode = searchParams.get('scheduleMode') as ScheduleModifyMode;
    const location = useLocation() as {
        state: {
            additionalPayload?: {
                brands: string[];
                markets: RunConfigMarket[];
                timePeriod: RelativeTimePeriod;
                category?: [];
            };
        } | null;
    };
    const additionalPayload = location?.state?.additionalPayload;

    useEffect(() => {
        (async () => {
            if (await validateOrExit()) {
                await loadConfig();
            }
            if (sku !== '456' && additionalPayload) {
                if (additionalPayload?.markets[0]?.remainingMarketRunConfig)
                    delete additionalPayload.markets[0]?.remainingMarketRunConfig;
            }
        })();
    }, [sku]);

    useEffect(() => {
        if (enableScheduleReport) {
            fetchScheduledSeriesNames();
        }
    }, [enableScheduleReport]);

    const loadConfig = async () => {
        try {
            const report = getProductBySku(sku!);
            if (defaultRunConfig?.datatype) {
                if (additionalPayload) {
                    let newDefaultConfig = {} as ReportRunConfig;
                    newDefaultConfig.focusBrands = additionalPayload?.brands;
                    newDefaultConfig.timePeriod = additionalPayload?.timePeriod;
                    newDefaultConfig.categories = additionalPayload?.category;
                    newDefaultConfig.markets = additionalPayload?.markets;
                    setDefaultReport({ ...defaultRunConfig, ...newDefaultConfig });
                }
            }
            // todo: show message if there is no matching config for he sku
            if (!report) {
                await byzzerAlert({
                    // @ts-ignore
                    title: 'Product Not Found',
                    content: `We were unable to find the product you are trying to configure.`,
                });

                return navigate(-1);
            }

            // todo: add support normalizing reports so this can just be check of single type instead of all this
            setReportType(report.metadata.reportOptions?.reportType.startsWith('smart') ? 'smart' : 'core');
            setReport(report);

            let runConfig = {};

            try {
                if (reportRunId) { // Normal report run case eith run a new report or run with latest date from report history
                    const reportConfigResponse = await getReportRunConfigById(Number(reportRunId));
                    runConfig = getReportRunConfigData(reportConfigResponse.sku, reportConfigResponse.configuration);
                } else if (scheduleId && scheduleMode) { // Schedule report case from schedule report history
                    const scheduledReportResponse = await getScheduledReportById(Number(scheduleId));
                    const { scheduleInfo } = scheduledReportResponse || {};

                    if (!scheduleInfo) return;

                    const scheduleObj = {
                        frequency: scheduleInfo.frequency,
                        deliveryDay: scheduleInfo.deliveryDay,
                        deliveryGroupType: scheduleInfo.deliveryGroupType,
                        deliverToUserIds: scheduleInfo.deliverToUserIds,
                        duration: scheduleInfo.deliveryCount,
                        scheduleDates: scheduleInfo.scheduleDates?.map((dt) => ({
                            endDate: dt?.reportWeekEnding,
                            deliveryDate: dt?.reportDeliverAt,
                        })),
                        startingTimePeriod: scheduleInfo.startingTimePeriod,
                        timePeriods: scheduleInfo.timePeriods,
                    };
                    
                    runConfig = getReportRunConfigData(scheduledReportResponse.sku, scheduledReportResponse.runConfig);

                    if (scheduleMode === 'editSchedule') {
                        setSchedule(scheduleObj);
                        setInitialSchedule(scheduleObj);
                        setEnableScheduleReport(true);
                    } else if (scheduleMode === 'editScheduleSeries') {
                        setScheduledRunConfig(scheduledReportResponse.runConfig);
                    }
                } else {
                    runConfig = getDefaultRunConfigBySku(sku);
                }
            } catch (error) {
                console.error(error);
            }

            setDefaultRunConfig(runConfig);

            if (additionalPayload) {
                defaultRunConfig.focusBrands = additionalPayload?.brands;
                defaultRunConfig.timePeriod = additionalPayload?.timePeriod;
                defaultRunConfig.categories = additionalPayload?.category || ['COFFEE'];
                defaultRunConfig.markets = additionalPayload?.markets;
                setDefaultRunConfig(defaultRunConfig);
            }
            if (runType !== 'adhoc') {
                const credits = await getMySubscriptionUsage();
                setBasicReportsUsage(credits.basicReports);
            }

            if (runType === 'adhoc' || runCreditId) {
                const runConfigOptionsForCreditId = await getRunConfigOptionsByCreditId(Number(runCreditId));
                setRunConfigOptions(runConfigOptionsForCreditId);
            } else {
                const reportRunConfigOptions = getRunConfigOptionsBySku(sku!);
                setRunConfigOptions(reportRunConfigOptions as RunConfigOptions[]);
            }
        } finally {
            setGenerating(false);
        }
    }

    const getReportRunConfigData = (reportRunSku: string, config: ReportRunConfig): Partial<ReportRunConfig> => {
        const { categories: selectedCategories } = config;

        const {
            metadata: { configOptions, dataType },
        } = getProductBySku(reportRunSku);

        const {
            includeBrands,
            includeCharacteristicDimensions,
            includeAttributeGroup,
            includeProductSubcategory,
            includeOmniProducts,
            includeCharacteristics,
        } = configOptions.find((options) => options.type === 'product') as ProductRunConfigOptions;

        const isOmniReport = dataType === 'omni';

        const includedCategories: string[] = [];
        const excludedCategories: string[] = [];

        selectedCategories?.forEach((selectedCategory: string) => {
            const isIncluded = categoryAppearsInGivenList(
                selectedCategory,
                isOmniReport ? subscriptionOmniCategories : subscriptionCategories
            );

            if (isIncluded) {
                includedCategories.push(selectedCategory);
            } else {
                excludedCategories.push(selectedCategory);
            }
        });

        const unsubscribedCatsWereRemoved = excludedCategories.length > 0;

        const reportConfigFilteredForSubscribedCategories: ReportRunConfig = {
            ...config,
            categories: includedCategories,
            ...(unsubscribedCatsWereRemoved
                ? {
                      ...(includeBrands ? { brands: [] } : {}),
                      ...(includeProductSubcategory ? { subcategories: [] } : {}),
                      ...(includeCharacteristicDimensions ? { productDimensions: [] } : {}),
                      ...(includeAttributeGroup ? { productDimensions: [] } : {}),
                      ...(includeOmniProducts ? { omniFocusProducts: [] } : {}),
                      ...(includeCharacteristics ? { characteristics: [] } : []),
                      markets: [],
                      subMarkets: [],
                  }
                : {}),
            metadata: {
                excludedCategories,
            },
        };

        if (reportRunSku === sku) {
            return reportConfigFilteredForSubscribedCategories;
        } else {
            return getReportRunConfig(sku, reportConfigFilteredForSubscribedCategories);
        }
    };

    const validateOrExit = async (): Promise<boolean> => {
        if (!['subscription', 'adhoc'].includes(runType)) {
            await byzzerAlert({
                // @ts-ignore
                title: 'Invalid Report Run',
                content: 'Only subscription and adhoc runs are supported.',
            });
            navigate(-1);
            return false;
        }

        if (runType === 'subscription' && !subscribedSkus.includes(sku as string)) {
            await byzzerAlert({
                // @ts-ignore
                title: 'Product Not Available',
                content: 'The product you selected is not available in your current subscription.',
            });
            navigate(-1);
            return false;
        }

        if (runType === 'adhoc' && !runCreditId) {
            await byzzerAlert({
                // @ts-ignore
                title: 'Invalid Credit',
                content: 'You have not purchased this ad hoc report.',
            });
            navigate(-1);
            return false;
        }

        return true;
    }

    const generateReport = async (runConfig: ReportRunConfig): Promise<void> => {
        try {
            setGenerating(true);
            let reportId: string | undefined; // pizza
            
            if (runType === 'subscription' && sku) {
                if (runConfig.datatype === 'osh') {
                    reportId = await createSubscriptionOshReportRun(sku, runConfig);
                } else {
                    reportId = await createSubscriptionReportRun(sku, runConfig);
                }
            } else if (runType === 'adhoc' && runCreditId) {
                reportId = await createAdHicReportRun(runCreditId, runConfig);
            }

            if (reportId) {
                navigate(`/report/${reportId}`, { replace: true });
            }
        } catch (err) {
            showErrorModal(err);
            setGenerating(false);
        }
    }

    const handleEnableScheduleReport = (): void => {
        setEnableScheduleReport(true);
    };

    const handleScheduleDataChange = (e: ByzzerChangeEvent<Schedule>): void => {
        setSchedule(e.value);
    };

    const onCreateSchedule = async (runConfig: ReportRunConfig, scheduleData: Schedule): Promise<void> => {
        try {
            const createScheduleResp = await openCreateReportScheduleModal({
                seriesName: '', // we are handling seriesName in modal itself but type is expecting that so it's a hack
                schedule: scheduleData,
                runConfig,
                seriesNames: scheduledSeriesNames,
                sku: sku!,
                createScheduleReport,
            });

            if (createScheduleResp) {
                navigate(`/dashboard/my_reports/scheduled`, { replace: true });
            }
        } catch (err) {
            console.error('error in report run config editor', err);
            byzzerAlert({
                type: 'error',
                title: 'Create Schedule Failed',
                content: (
                    <>
                        <p>There was an unexpected error during this run creation.</p>
                        <p>Please contact the support team for additional assistance.</p>
                    </>
                ),
            });
        } finally {
            setGenerating(false);
        }
    };

    const onRescheduleReport = async (scheduleData: Schedule): Promise<void> => {
        try {
            if (!scheduleId) return;
            
            const rescheduleResp = await openModifyReportRescheduleModal({
                scheduleId: Number(scheduleId!),
                scheduleInfo: scheduleData!,
                rescheduleReport,
            });

            if (rescheduleResp) {
                navigate(`/dashboard/my_reports/scheduled`, { replace: true });
            }
        } catch (err) {
            console.error('error in report run config editor', err);
            byzzerAlert({
                type: 'error',
                title: 'Reschedule Failed',
                content: (
                    <>
                        <p>There was an unexpected error during this run creation.</p>
                        <p>Please contact the support team for additional assistance.</p>
                    </>
                ),
            });
        } finally {
            setGenerating(false);
        }
    }

    const onUpdateScheduleReportConfig = async (runConfig: ReportRunConfig): Promise<void> => {
        try {            
            if (!scheduleSeriesId) return;
            
            const updateConfigResp = await openModifyReportScheduleConfigModal({
                seriesId: Number(scheduleSeriesId!),
                runConfig,
                updateScheduledReportConfig,
            });

            if (updateConfigResp) {
                navigate(`/dashboard/my_reports/scheduled`, { replace: true });
            }
        } catch (err) {
            console.error('error in report run config editor', err);
            byzzerAlert({
                type: 'error',
                title: 'Reschedule Failed',
                content: (
                    <>
                        <p>There was an unexpected error during this run creation.</p>
                        <p>Please contact the support team for additional assistance.</p>
                    </>
                ),
            });
        } finally {
            setGenerating(false);
        }
    }

    const fetchScheduledSeriesNames = async (): Promise<void> => {
        try {
            const response = await getScheduledReportSeriesNames();
            setScheduledSeriesNames(response.scheduledSeriesNames);
        } catch (error) {
            console.error('error in fetchScheduledSeriesNames');
        }
    };

    // @ts-ignore
    return (
        <DashboardContent
            title={<>Design Your {report?.title} Report</>}
            className={`${baseClassName}__container`}
            loading={!runConfigOptions}
            numberTag={
                <NumberTagReportRuns reportType={reportType} runType={runType} reportRuns={basicReportsUsage.limit} />
            }
            extras={
                <div className={`${baseClassName}__header-actions`}>
                    <ByzzerButton type={'negative'} label={'Cancel'} onClick={() => navigate(-1)} />
                </div>
            }
        >
            {runConfigOptions && (
                <ReportRunConfigWizard
                    sku={sku!}
                    onComplete={generateReport}
                    runType={runType}
                    defaultValues={defaultRunConfig}
                    busy={generating}
                    runConfigOptions={runConfigOptions}
                    enableScheduleReport={enableScheduleReport}
                    onEnableScheduleReport={handleEnableScheduleReport}
                    scheduleData={schedule}
                    onScheduleDataChange={handleScheduleDataChange}
                    onCreateSchedule={onCreateSchedule}
                    onRescheduleReport={onRescheduleReport}
                    mode={scheduleMode}
                    initialSchedule={initialSchedule}
                    scheduledRunConfig={scheduledRunConfig}
                    onUpdateScheduleReportConfig={onUpdateScheduleReportConfig}
                />
            )}
        </DashboardContent>
    );
}

function NumberTagReportRuns({ reportType, runType, reportRuns }) {
    if (runType === 'adhoc' || reportRuns === Infinity || reportRuns === 0) return <></>;

    return (
        <div className={`report-run-selector__tag`}>
            {'-1 run'}
            <ByzzerTipIcon
                tip={`This will deduct 1 ${reportType} run credit from your subscription`}
                tipLocation="right"
            />
        </div>
    );
}

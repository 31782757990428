import {CharacteristicCondition} from "@/types/ReportRun";
import {
    ByzzerChangeEventHandler,
    ByzzerConditionInput,
    ByzzerSearchableConditionInput,
    ByzzerSelectOption
} from "@byzzer/ui-components";
import {ByzzerOshCharacteristicContext, ByzzerOshCharacteristicOption} from "./ByzzerOshCharacteristicBuilder";
import {ReactNode, useContext, useEffect, useRef, useState} from "react";
import {useTenantApi} from "@/hooks/useTenantApi";
import classnames from "classnames";

export type ByzzerOshCharacteristicConditionInputProps = {
    value?: CharacteristicCondition;
    onChange?: ByzzerChangeEventHandler<CharacteristicCondition>;
    characteristics?: ByzzerOshCharacteristicOption[];
    includeLabels?: boolean;
    categories: string[];
    brand?: string;
    disabledKeys?: string[];
    name?: string;
    className?: string;
    actions?: ReactNode;
    excludeCharacteristicIsNot?: boolean;
    fieldsToRenderAsLargeList?: string[];
    renderLargeList?: boolean;
    searchableFields?: string[];
    maxAllowedValue?: number;
}


const CHARACTER_COMPARISON_OPERATIONS = ['is', 'is not'];
const OPERATIONS_EXCLUDING_IS_NOT = ['is']; // adding for exclusion of 'is not' option as part of (BYZ-9680)

export function ByzzerOshCharacteristicConditionInput({
    className,
    onChange,
    includeLabels,
    value,
    categories,
    brand,
    disabledKeys,
    name,
    actions,
    excludeCharacteristicIsNot,
    fieldsToRenderAsLargeList,
    renderLargeList,
    searchableFields,
    maxAllowedValue,
    ...props
}: ByzzerOshCharacteristicConditionInputProps) {
    const { getOshCharacteristicValues } = useTenantApi();
    const context = useContext(ByzzerOshCharacteristicContext);
    const baseClassName = 'characteristic-condition';
    const labels = includeLabels
        ? {
              keyLabel: 'Characteristic',
              operationLabel: 'Condition',
              valueLabel: 'Values',
          }
        : {};

    const [internalValue, setInternalValue] = useState<CharacteristicCondition>();
    const [internalValueOptions, setInternalValueOptions] = useState<(ByzzerSelectOption | string)[]>([]);
    const [valueNoOptionsMessage, setValueNoOptionsMessage] = useState<string | undefined>();
    const loadedWithValueOptions = useRef<Boolean>(false);

    const keyLoadingValueOptionsMessage = Boolean(categories.length) && Boolean(!context.characteristics?.length) && !internalValue?.characteristic ? 'Loading options...' : undefined;
    const loadingValueOptionsMessage = internalValue?.characteristic! && !internalValueOptions?.length ? 'Loading options...' : undefined;

    useEffect(() => {
        if (!loadedWithValueOptions.current && value?.characteristic && !internalValueOptions.length) { // triggers dynamic lookup of value options on load, when value is passed in
            handleKeyChange({
                value: value?.characteristic,
                name
            })
        } else if (loadedWithValueOptions.current && value) { // not sure if &&value is needed
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (!loadedWithValueOptions.current && !internalValueOptions.length) { // responsible for setting 'value' (third select) after value options have loaded, when default value is passed into conditioninput
            loadedWithValueOptions.current = true;
            setInternalValue(value)
        }
    }, [internalValueOptions]);



    function handleChange(e: any) {
        const { value: {
            key, operation, value: charValue, isCustom, characteristicDisplayValue
        } } = e;
        onChange?.({
            value: {
                characteristic: key,
                condition: operation,
                value: charValue?.map((characteristic: ByzzerSelectOption | string) => (characteristic as ByzzerSelectOption)?.value ?? (characteristic as string)),
                isCustom: isCustom,
                characteristicDisplayValue: characteristicDisplayValue
            },
            name
        })
    }

    async function getAndsetInternalValueOptions(value: string) {
        if (!searchableFields?.includes(value)) {            
            const charValuesForCategoriesResponse = await getOshCharacteristicValues(categories, value);
            setInternalValueOptions(charValuesForCategoriesResponse);
        }
    }

    async function handleKeyChange(e: ByzzerChangeEvent<string | undefined | null>) {
        if (e?.value) {
            getAndsetInternalValueOptions(e.value);
        } else {
            setInternalValue(undefined);
            setInternalValueOptions([]);
        }
    }

    // todo:- need to revisit this as it is not required
    async function search(searchText: string): Promise<string[] | ByzzerSelectOption[]> {
        return [];
    }

    if (Boolean(internalValue?.characteristic) && searchableFields?.includes(internalValue?.characteristic!)) {
        return (
            <ByzzerSearchableConditionInput
                {...labels}
                value={{
                    key: internalValue?.characteristic!,
                    operation: internalValue?.condition,
                    value: internalValue?.value,
                }}
                className={classnames(baseClassName, className)}
                keyOptions={context.characteristics.map((c) => ({
                    display: c.characteristic,
                    value: c.characteristicsCode,
                }))}
                operationOptions={excludeCharacteristicIsNot ? OPERATIONS_EXCLUDING_IS_NOT : CHARACTER_COMPARISON_OPERATIONS}
                onKeyChange={handleKeyChange}
                disabledKeys={disabledKeys}
                minSearchCharacters={4} // todo: make this dynamic. 4 works for upc but might need to be diferent for other fields in future
                search={search} // todo: make this dynamic
                actions={actions}
                name={name}
                onChange={handleChange}
                valueNoOptionsMessage={valueNoOptionsMessage}
                onMenuOpen={() => setValueNoOptionsMessage(undefined)} // TODO: Rename valueNoOptionsMessage to 'noValueOptionsMessage' everywhere
            />
        )
    }

    return (
        <ByzzerConditionInput
            // {...props}
            {...labels}
            value={{
                key: internalValue?.characteristic!,
                operation: internalValue?.condition,
                value: internalValue?.value,
            }}
            className={classnames(baseClassName, className)}
            keyOptions={context.characteristics.map((c) => ({
                display: c.characteristic,
                value: c.characteristicsCode,
            }))}
            maxAllowedValues={maxAllowedValue}
            operationOptions={excludeCharacteristicIsNot ? OPERATIONS_EXCLUDING_IS_NOT : CHARACTER_COMPARISON_OPERATIONS}
            allowMultipleValues={true}
            valueOptions={internalValueOptions}
            onKeyChange={handleKeyChange}
            disabledKeys={disabledKeys}
            onChange={handleChange}
            actions={actions}
            keyPlaceholder={keyLoadingValueOptionsMessage}
            keyLoadingMessage={keyLoadingValueOptionsMessage}
            keyNoOptionsMessage={keyLoadingValueOptionsMessage}
            valuePlaceholder={loadingValueOptionsMessage}
            valueLoadingMessage={loadingValueOptionsMessage}
            valueNoOptionsMessage={loadingValueOptionsMessage}
            renderLargeList={renderLargeList} // todo: rename this to something like renderValuesAsLargeList
        />
    );
}

import React, { useRef, ReactNode, useEffect } from 'react';
import useState from 'react-usestateref';
import classnames from 'classnames';
import {
    ByzzerSelectOption,
    ByzzerChangeEvent,
    ByzzerConditionInput,
    ByzzerSearchableConditionInput,
    ByzzerChangeEventHandler,
    ByzzerSelectOptionGroup,
} from '@byzzer/ui-components';
import { useApp } from '@/contexts/UserContext';
import { useTenantApi } from '@/hooks/useTenantApi';
import { DemographicCondition } from '@/types/ReportRun';

export type ByzzerOshDemographicOption = {
    display: string;
    value: string;
};

export type ByzzerOshDemographicConditionInputProps = {
    value?: DemographicCondition;
    onChange?: ByzzerChangeEventHandler<DemographicCondition>;
    demographics?: ByzzerOshDemographicOption[];
    operationOptions: (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[];
    maxAllowedValues?: number | undefined;
    includeLabels?: boolean;
    disabledKeys?: string[];
    searchableFields?: string[];
    search?: any;
    className?: string;
    actions?: ReactNode;
    name?: string;
    renderLargeList?: boolean;
};
const baseClassName = 'byz-osh-demographic-condition';

export const ByzzerOshDemographicConditionInput = ({
    value,
    onChange,
    demographics,
    includeLabels,
    disabledKeys,
    search,
    className,
    actions,
    name,
    renderLargeList,
    operationOptions,
    maxAllowedValues,
    ...props
}: ByzzerOshDemographicConditionInputProps) => {
    const { getOshDemographics, getOshDemographicValues } = useTenantApi();
    const labels = includeLabels
        ? {
              keyLabel: 'Demographic',
              operationLabel: 'Condition',
              valueLabel: 'Values',
          }
        : {};

    const [demographicOptions, setDemographicOptions] = useState<string[]>([]);
    const [internalValue, setInternalValue] = useState<DemographicCondition>();
    const [internalValueOptions, setInternalValueOptions] = useState<
        (ByzzerSelectOption | string | ByzzerSelectOptionGroup)[]
    >([]);
    const loadedWithValueOptions = useRef<Boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                const resp = await getOshDemographics();
                setDemographicOptions(resp);
            } catch (error) {
                console.log('error', error);
            }
        })();
    }, []);

    useEffect(() => {
        if (!loadedWithValueOptions.current && value?.demographic && !internalValueOptions.length) {           
            // triggers dynamic lookup of value options on load, when value is passed in
            // console.log("(2) DemographicConditionInput - useEffect (INITIAL) / value (condition) LOADED ===>> ", value)
            handleKeyChange?.({
                value: value?.demographic,
                name,
            });
        } else if (loadedWithValueOptions.current && value) {
            // not sure if &&value is needed
            setInternalValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (!loadedWithValueOptions.current && !internalValueOptions.length) {
            // responsible for setting 'value' (third select) after value options have loaded, when default value is passed into conditioninput
            // console.log("(3) DemographicConditionInput - useEffect (INITIAL)  / internalValueOptions updated - 'internalValueOptions' ===>> ", internalValueOptions)
            loadedWithValueOptions.current = true;
            setInternalValue(value);
        }
    }, [internalValueOptions]);

    function handleChange(e: any) {
        const newValue = {
            demographic: e.value?.key,
            condition: e.value?.operation,
            value: e.value?.value?.map(
                (demographicValue: ByzzerSelectOption | string) =>
                    (demographicValue as ByzzerSelectOption)?.value ?? (demographicValue as string)
            ),
            data: e.value.data,
        };

        if (onChange) {
            onChange?.({
                value: newValue,
                name,
            });
        } else {
            setInternalValue(newValue);
        }
    }

    async function handleKeyChange(e: ByzzerChangeEvent<string | undefined | null>) {
        if (e?.value) {
            const values = await getOshDemographicValues(e?.value);
            setInternalValueOptions(values);
        }
    }

    return (
        <ByzzerConditionInput
            // {...props}
            {...labels}
            value={{
                key: internalValue?.demographic,
                operation: internalValue?.condition,
                value: internalValue?.value,
                data: internalValue?.data,
            }}
            className={classnames(baseClassName, className)}
            keyOptions={demographicOptions}
            operationOptions={operationOptions}
            allowMultipleValues={true}
            valueOptions={internalValueOptions}
            onKeyChange={handleKeyChange}
            disabledKeys={disabledKeys}
            actions={actions}
            name={name}
            onChange={handleChange}
            renderLargeList={renderLargeList}
            maxAllowedValues={maxAllowedValues}
        />
    );
};

export default ByzzerOshDemographicConditionInput;

ByzzerOshDemographicConditionInput.displayName = 'ByzzerOshDemographicConditionInput';

import { ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption } from '@byzzer/ui-components';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import { SelectorLabelInfo } from '../SelectorLabelInfo';
import { ByzOshCharacteristics } from '@/types/ReportRun';

const baseClassName = 'byz-osh-characteristics-dimention-select';

const ByzOshCharacteristicOptions: ByzzerSelectOption<any, ByzOshCharacteristics>[] = [
    {
        display: 'BRAND OWNER HIGH',
        value: 'BRAND OWNER HIGH',
    },
    {
        display: 'BRANDED VS PRIVATE LABEL',
        value: 'BRANDED VS PRIVATE LABEL',
    },
    {
        display: 'BRAND HIGH',
        value: 'BRAND HIGH',
    },
];

export type ByzOshCharacteristicsDimensionSelectProps = {
    name?: string;
    value?: ByzOshCharacteristics[];
    categories?: string[];
    onChange?: ByzzerChangeEventHandler<ByzOshCharacteristics[]>;
    maxSelections?: number;
    includeCustomCharacteristics?: boolean;
    allowClear?: boolean;
    sku?: string;
    productLevel?: string;
    label?: ReactNode;
    required?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function ByzzerOshCharacteristicsDimensionSelect({
    className,
    onChange,
    value,
    name,
    categories,
    maxSelections,
    includeCustomCharacteristics = true,
    allowClear,
    label,
    sku = '',
    productLevel = 'Category',
    required,
    ...props
}: ByzOshCharacteristicsDimensionSelectProps) {
    const [selectedCharacteristics, setSelectedCharacteristics] = useState<ByzOshCharacteristics[]>([]);

    const CHAR_DIM_LABEL = (
        <>
            <p>
                <b>This report includes fields for characteristics that you get to define.</b>
            </p>
            <span>
                Choose {maxSelections} characteristic(s) to show in your report. Category, Subcategory, and Brand will
                always be included by default.
            </span>
            <span>
                The dimension you choose will break the category down into comparative groups based on that
                characteristic. Do you want to compare different brands, or the performance of different features such
                as flavor or pack type?
            </span>
        </>
    );

    useEffect(() => {
        setSelectedCharacteristics(value ?? []);
    }, [value]);

    function handleChange(e: ByzzerChangeEvent<ByzOshCharacteristics[]>) {
        const selectedValues = Array.isArray(e.value) ? e.value : [e.value];

        // Update parent component if onChange is provided
        if (onChange) {
            onChange({
                name,
                value: selectedValues,
            });
        }

        // Update the local state for selected characteristics
        setSelectedCharacteristics(selectedValues);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            {Number(sku) ? (
                <SelectorLabelInfo
                    sku={sku}
                    selectorCode={'omniShopperProductDimensions'}
                    required={required}
                    includeSuffix={!required}
                    max={maxSelections}
                    isLabelBold={true}
                />
            ) : (
                CHAR_DIM_LABEL
            )}

            <ByzzerSelect
                name={name}
                value={selectedCharacteristics}
                onChange={handleChange}
                label={label}
                options={ByzOshCharacteristicOptions}
                placeholder={'Select from the list'}
                allowClear={allowClear}
                maxSelections={Number(maxSelections) > 0 ? maxSelections : undefined}
                allowMultiple={Number(maxSelections) > 0} // greater than zero means they set a max, would be undefined otherwise.
            />
        </div>
    );
}

export default ByzzerOshCharacteristicsDimensionSelect;

/* eslint-disable react-hooks/rules-of-hooks */
import './ByzzerOshCharacteristicBuilder.scss';
import React, { createContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { CharacteristicCondition as ByzzerOshCharacteristicCondition } from '@/types/ReportRun';
import { clone } from 'lodash';
import { WithUid } from '@/types/WithUid';
import { nanoid } from 'nanoid';
import { ByzzerOshCharacteristicConditionInput } from './ByzzerOshCharacteristicCriteriaInput';
import { upcCharacteristicOption } from '@/config/globalVars';

type ByzzerOshCharacteristicsCriteria = ByzzerOshCharacteristicCondition[];

const BLANK_CHARACTERISTIC_CONDITION: ByzzerOshCharacteristicCondition = {
    characteristic: '',
    characteristicDisplayValue: '',
    condition: 'is',
    value: [],
    isCustom: false,
};

// todo:- The characteristics should come from backend API
const BYZZER_OSH_CHARACTERISTICS = [
    {
        chr_code: 'branded_vs_private_label',
        chr_display_name: 'BRANDED VS PRIVATE LABEL',
    },
    {
        chr_code: 'brand_owner_high',
        chr_display_name: 'BRAND OWNER HIGH',
    },
    {
        chr_code: 'brand_high',
        chr_display_name: 'BRAND HIGH',
    },
];

export type ByzzerOshCharacteristicOption = {
    characteristicsCode: string;
    characteristic: string;
    isCustom?: boolean;
};

type ByzzerOshCharacteristicContextValue = {
    characteristics: ByzzerOshCharacteristicOption[];
    selectedCharacteristics: string[];
};

export const ByzzerOshCharacteristicContext = createContext<ByzzerOshCharacteristicContextValue>({} as any);

export type ByzzerOshCharacteristicBuilderProps = {
    name?: string;
    value?: ByzzerOshCharacteristicsCriteria;
    categories?: string[];
    brand?: string;
    onChange?: ByzzerChangeEventHandler<ByzzerOshCharacteristicsCriteria>;
    onCharacteristicsChange?: (characteristics: string[]) => void;
    joinText?: 'and' | 'or' | string;
    maxConditions?: number;
    minConditions?: number;
    showLabelsOnAllRows?: boolean; // defaults to false
    excludeCharacteristicIsNot?: boolean;
    fieldsToRenderAsLargeList?: string[];
    includeUpcOption?: boolean;
    onRemoveUnavailableChars?: (omniShopperCharacteristicsCriteria: ByzzerOshCharacteristicCondition[]) => void;
    maxAllowedValue?: number;
} & OnlyRenderableIf &
    Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>;

export function ByzzerOshCharacteristicBuilder({
    className,
    onChange,
    value,
    name,
    categories,
    brand,
    onlyRenderIf = true,
    joinText,
    showLabelsOnAllRows = false,
    maxConditions,
    minConditions = 1, //added default value so 1 row is always available
    excludeCharacteristicIsNot = false, //added default value as false this can be changed in alby config for enabling/disabling 'is not' option for characteristic (BYZ-9680)
    fieldsToRenderAsLargeList,
    includeUpcOption,
    onRemoveUnavailableChars,
    maxAllowedValue,
    ...props
}: ByzzerOshCharacteristicBuilderProps) {
    if (onlyRenderIf === false) return <></>;

    const baseClassName = 'byz-osh-characteristic-builder';
    const [conditions, setConditions] = useState<WithUid<ByzzerOshCharacteristicCondition, string>[]>([]);
    const [contextValue, setContextValue] = useState<ByzzerOshCharacteristicContextValue>({
        characteristics: [],
        selectedCharacteristics: [],
    });

    const basicCharacteristics = useRef<any[]>([]);
    const disabledKeys = useRef<string[]>([]);
    const initialValueLoaded = useRef<Boolean>(false);

    useEffect(() => {        
        if (categories?.length) {
            fetchCharacteristicsForCategories();
            updateCharacteristicsContext();
        } else {
            basicCharacteristics.current = [];
            updateCharacteristicsContext();
        }
    }, [categories]);

    useEffect(() => {
        // TODO - create logic to map given value to conditions.  probably only needed when defaults are given or rendering for first time
        // only do this on load for given value list.  otherwise, changes will be handled in onchange to prevent rebuidling list every time.

        if (!initialValueLoaded.current) {
            if (value?.[0]?.characteristic?.length) {
                // some preset value(s) to load, either from a prior report run, user defaults, or coming back to this after unmounting
                // console.log("(SHOULD I FIRE? LOADING DEFAULT OR RE-MOUNTING...?) CharacteristicCriteriaBuilder - 'value', 'categories' ===>> ", value, categories)
                let conditionsWithUids = value?.map((condition) => ({
                    value: condition,
                    uid: nanoid(),
                }));
                updateCharacteristicsContext();
                setConditions(conditionsWithUids);
                initialValueLoaded.current = true; // finished initial load script, set to true to prevent above from reoccuring
            } else {
                // after mounting and after clicking radio button and no prior value to load, create a new default condition
                // console.log("(I THINK I SHOULD FIRE TO START THIS THING OFF?!) CharacteristicCriteriaBuilder - 'value', 'categories' ===>> ", value, categories)
                setConditions([createDefaultCondition()]);
            }
        }
    }, [value]);

    useEffect(() => {
        disabledKeys.current = conditions.map((condition) => condition.value.characteristic ?? '');
    }, [conditions]);

    const fetchCharacteristicsForCategories = () => {
        // todo:- The characteristics should come from backend API
        const characteristicsForCategories = BYZZER_OSH_CHARACTERISTICS.map(
            (chr): Characteristic => ({ code: chr.chr_code, displayName: chr.chr_display_name })
        );
        const basicCharacteristicsValue: ByzzerOshCharacteristicOption[] = characteristicsForCategories.map(
            (characteristic) => ({
                // TODO: From DoD refactor merge - see what the correct values are for these
                characteristicsCode: characteristic?.code,
                characteristic: characteristic?.displayName,
                isCustom: false,
            })
        );
        basicCharacteristics.current = basicCharacteristicsValue.sort((charA, charB) =>
            charA.characteristic.localeCompare(charB.characteristic, 'en', { sensitivity: 'base' })
        );
    };

    function updateCharacteristicsContext() {
        setContextValue((value) => ({
            ...value,
            characteristics: basicCharacteristics.current,
        }));
    }

    function updateStates(
        conditionsWithUids: WithUid<ByzzerOshCharacteristicCondition, string>[],
        onChange?: ByzzerChangeEventHandler<ByzzerOshCharacteristicsCriteria>
    ) {
        if (onChange) {
            // onChange should fire together with setConditions
            onChange({
                name,
                value: conditionsWithUids
                    .filter((condition) => condition?.value?.characteristic)
                    .map((condition) => condition.value), // send only condition value without uid
            });
        }
        setConditions(conditionsWithUids); // setConditions should fire together with onChange; triggers render of conditions, which kicks off updates in children conditions elements
    }

    function handleChange(e: ByzzerChangeEvent<ByzzerOshCharacteristicCondition>, index) {
        const conditionsWithUids = conditions.map((condition, i) => {
            if (condition.uid === index) {
                const characteristic = contextValue.characteristics.find(
                    (c) => c.characteristicsCode === e.value.characteristic
                );
                let value = e.value;
                value.characteristicDisplayValue = characteristic?.characteristic ?? '';
                value.isCustom = characteristic?.isCustom ?? false;
                return { value, uid: condition.uid };
            } else {
                return condition;
            }
        });
        // since this is currently not technically a 100% controlled component, for performance/UX reasons, state update functions are combined as one function and should fire together and be treated as one until better solution is implemented
        updateStates(conditionsWithUids, onChange);
    }

    function createDefaultCondition() {
        return { value: clone(BLANK_CHARACTERISTIC_CONDITION), uid: nanoid() };
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerOshCharacteristicContext.Provider value={contextValue}>
                {conditions.map(({ value, uid }, index) => (
                    <React.Fragment key={uid}>
                        {joinText && index > 0 && (
                            <div className={classnames(`${baseClassName}__label-text`)}>{joinText.toUpperCase()}</div>
                        )}
                        <ByzzerOshCharacteristicConditionInput
                            value={value}
                            includeLabels={showLabelsOnAllRows ? true : index === 0}
                            onChange={(e) => handleChange(e, uid)}
                            disabledKeys={disabledKeys.current}
                            categories={categories ?? []}
                            brand={brand}
                            name={name}
                            excludeCharacteristicIsNot={excludeCharacteristicIsNot}
                            renderLargeList={fieldsToRenderAsLargeList?.includes(value?.characteristic!)} // todo: rename this to something like renderValuesAsLargeList
                            searchableFields={[upcCharacteristicOption?.code]}
                            maxAllowedValue={maxAllowedValue}
                        />
                    </React.Fragment>
                ))}
            </ByzzerOshCharacteristicContext.Provider>
        </div>
    );
}

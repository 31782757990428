import './TimePeriodRunConfigFilters.scss';
import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {MonthEndingTimePeriod, TimePeriod, WeekEndingTimePeriod} from "@/types/ReportRun";
import {ByzzerSelect, ByzzerTipIcon} from "@byzzer/ui-components";
import {
    ReportRunConfigWizardContext
} from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext";
import {TimePeriodPicker} from "@/components/TimePeriodPicker";
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';

export type TimePeriodRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<TimePeriod>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: TimePeriod;
    summary?: ReactNode;
    datatype?: 'omni' | 'rms' | 'cps' | 'osh';
    includeChartData?: boolean;    
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const preDefinedChartConfigs: string[] = ['Weekly', 'Monthly', 'Quarterly'];

const baseClassName = 'time-period-run-config-filters';

export function TimePeriodRunConfigFilters(
    {
        className,
        onChange,
        onValidityChange,
        name,
        datatype,
        value,
        includeChartData,        
        ...props
    }: TimePeriodRunConfigFiltersProps) {
    const {value: contextValue, onChange: onContextChange, sku} = useContext(ReportRunConfigWizardContext);
    const timePeriodType: 'omni' | 'osh' | 'relative' =
        datatype === 'omni' ? 'omni' : datatype === 'osh' ? 'osh' : 'relative';
    
    const [timePeriod, setTimePeriod] = useState<TimePeriod>({
        type: timePeriodType,
    });
  
    const wasValid = useRef<boolean>(false);    

    useEffect(() => {
        let isValid = false;
        if (['osh', 'omni', 'relative'].includes(timePeriod.type)) {
            isValid = Boolean(timePeriod.period);
        } else if( ['week_ending', 'omni_custom'].includes(timePeriod.type) ) {
           
            if( timePeriod.type === 'week_ending'){
                const tp = (timePeriod as WeekEndingTimePeriod);
                isValid = Boolean(tp.period && tp.period.weeks! > 0 && tp.period.endDate);
            }
            if( timePeriod.type === 'omni_custom' ){
                const tp = (timePeriod as MonthEndingTimePeriod);
                isValid = Boolean(tp.period && tp.period.months! > 0 && tp.period.endDate);
            }
        }
        // BYZ-9084, VALIDATES if chartdata configuration is selected or not
        // if(sku && includeChartData && isValid){
        //     isValid = Boolean(timePeriod?.chartDataConfig);
        // }

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }

    }, [timePeriod]);

    useEffect(() => {        
        setTimePeriod(contextValue?.timePeriod ?? value ?? {
            type: datatype === 'omni' ? 'omni' : datatype === 'osh' ? 'osh' : 'relative',
        })
    }, [contextValue, value])


    function handleChange(e: ByzzerChangeEvent<TimePeriod>) {
        onChange?.({
            name,
            value: e.value
        });
        onContextChange?.('timePeriod', e.value);
        setTimePeriod(e.value);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <TimePeriodPicker
                name={'timePeriod'}
                value={timePeriod}
                onChange={handleChange}
                datatype={datatype}
                sku={sku}
                hideCustomDate={datatype === 'osh'}
            />
            {includeChartData && (
                <div className={`${baseClassName}__chart-data-config`}>
                    <ByzzerSelect
                        value={contextValue.chartDataConfigurationType}
                        label={
                            <>
                                {/* Chart Data Configuration:
                            <ByzzerTipIcon
                                type={'info'}
                                tip={
                                    <>
                                        This selection will organize your data in the Base vs Incremental stacked
                                        bar chart only
                                    </>
                                }
                                tipLocation='top'
                            /> */}
                                <SelectorLabelInfo sku={sku as string} selectorCode={'chartData'} />
                            </>
                        }
                        placeholder="Choose chart configuration"
                        onChange={(e) => onContextChange?.('chartDataConfigurationType', e.value)}
                        options={preDefinedChartConfigs}
                    />
                </div>
            )}
        </div>
    );
}

export default TimePeriodRunConfigFilters;

/**
 * The key should match with the selector unique code,refer alby code -MUST
 * handle internationalisation
 */

import { selectorLabel, selectorTipInfo } from "@/config/globalVars";

// todo: Remove references to this from components and instead, retrieve a value from it and pass it in using a prop like defaultLabel/Tip or customLabel/Tip instead.
export const defaultSelectorLabels = {
    focusBrands: {
        label: selectorLabel.focusBrands,
        tip: '',
    },
    categories: {
        label: selectorLabel.categories,
        tip: selectorTipInfo.categories,
    },
    brandAggregationLevel: {
        label: selectorLabel.brandAggregationLevel,
        tip: selectorTipInfo.categories,
    },
    manufacturers: {
        label: selectorLabel.manufacturers,
        tip: '',
    },
    characteristicDimensions: {
        label: selectorLabel.characteristicDimensions,
        tip: selectorTipInfo.characteristicDimensions,
    },
    characteristicFilters: {
        label: selectorLabel.characteristicFilters,
        tip: selectorTipInfo.characteristicFilters,
    },
    brands: {
        label: selectorLabel.brands,
        tip: '',
    },
    keyCharecteristics: {
        label: selectorLabel.keyCharecteristics,
        tip: selectorTipInfo.keyCharecteristics,
    },
    ppgId: {
        label: selectorLabel.ppgId,
        tip: '',
    },
    focusMarkets: {
        label: selectorLabel.focusMarkets,
        tip: '',
    },
    marketAlias: {
        label: selectorLabel.marketAlias,
        tip: '',
    },
    characteristicsAlias: {
        label: selectorLabel.characteristicsAlias,
        tip: '',
    },
    categoryAlias: {
        label: selectorLabel.categoryAlias,
        tip: selectorTipInfo.categoryAlias,
    },
    productAlias: {
        label: selectorLabel.productAlias,
        tip: selectorTipInfo.productAlias,
    },
    brandAlias: {
        label: selectorLabel.brandAlias,
        tip: selectorTipInfo.brandAlias,
    },
    manufacturerAlias: {
        label: selectorLabel.manufacturerAlias,
        tip: selectorTipInfo.manufacturerAlias,
    },
    keyCharecteristicsAlias: {
        label: selectorLabel.keyCharecteristicsAlias,
        tip: selectorTipInfo.keyCharecteristicsAlias,
    },
    presetTp: {
        label: selectorLabel.presetTp,
        tip: '',
    },
    customTp: {
        label: selectorLabel.customTp,
        tip: '',
    },
    demographicDimensions: {
        label: selectorLabel.demographicDimensions,
        tip: selectorTipInfo.demographicDimensions,
    },
    productSubCategory: {
        label: selectorLabel.productSubCategory,
        tip: '',
    },
    aggregationLevel: {
        label: selectorLabel.aggregationLevel,
        tip: selectorTipInfo.aggregationLevel,
    },
    attributeGroup: {
        label: selectorLabel.attributeGroup,
        tip: '',
    },
    attributes: {
        label: selectorLabel.attributes,
        tip: '',
    },
    salesThreshold: {
        label: selectorLabel.salesThreshold,
        tip: selectorTipInfo.salesThreshold,
    },
    growthThreshold: {
        label: selectorLabel.growthThreshold,
        tip: selectorTipInfo.growthThreshold,
    },
    remainingMarkets: {
        label: selectorLabel.remainingMarkets,
        tip: '',
    },
    remainingMarketToggle: {
        label: selectorLabel.remainingMarketToggle,
        tip: selectorTipInfo.remainingmarketToggle,
    },
    dimensionRunFilter: {
        label: selectorLabel.dimensionRunFilter,
        tip: '',
    },
    chartData: {
        label: selectorLabel.chartData,
        tip: selectorTipInfo.chartData,
    },
    omniCategories: {
        label: selectorLabel.omniCategories,
        tip: '',
    },
    omniProducts: {
        label: selectorLabel.omniProducts,
        tip: selectorTipInfo.omniProducts,
    },
    omniBrands: {
        label: selectorLabel.omniBrands,
        tip: '',
    },
    omniBrandFamily: {
        label: selectorLabel.omniBrandFamily,
        tip: '',
    },
    omniBrandLow: {
        label: selectorLabel.omniBrandLow,
        tip: '',
    },
    omniManufacturer: {
        label: selectorLabel.omniManufacturer,
        tip: '',
    },
    omniTotalMarkets: {
        label: selectorLabel.omniTotalMarkets,
        tip: selectorTipInfo.omniTotalMarkets,
    },
    omniSubMarkets: {
        label: selectorLabel.omniSubMarkets,
        tip: selectorTipInfo.omniSubMarkets,
    },
    omniShopperCategories: {
        label: selectorLabel.omniShopperCategories,
        tip: '',
    },
    omniShopperProductDimensions: {
        label: selectorLabel.characteristicDimensions,
        tip: '',
    },
    omniShopperCharacteristics: {
        label: selectorLabel.characteristicFilters,
        tip: selectorLabel.characteristicFilters,
    },
    omniShopperDemographics: {
        label: selectorLabel.omniShopperDemographics,
        tip: '',
    },
    omniShopperDemographicDimensions: {
        label: selectorLabel.demographicDimensions,
        tip: '',
    },
    /** Tab -Select Shopper Segment
     *  eg.Shopper Leakage Tree Report */
    demographicDimensionsMessage: {
        label:selectorLabel.demographicDimensionsMessage,
        tip: '',
    },
    enableDemographicsYes: {
        label: selectorLabel.enableDemographicsYes,
        tip: '',
    },
    enableDemographicsNo: {
        label: selectorLabel.enableDemographicsNo,
        tip: '',
    },
    /** Tab -Select Recipients
     *  eg.Design Your Growth Alert */
    alertRecipientJustMe: {
        label: selectorLabel.alertRecipientJustMe,
        tip: '',
    },
    alertRecipientMyTeam: {
        label: selectorLabel.alertRecipientMyTeam,
        tip: '',
    },
    alertRecipientEveryOne: {
        label: selectorLabel.alertRecipientEveryOne,
        tip: '',
    },
    alertRecipientSelectedMember: {
        label: selectorLabel.alertRecipientSelectedMember,
        tip: '',
    },
    /** Tab -Select Market to Watch
     *  eg.Design Your Growth Alert */
    comparisonMarketAccount: {
        label: selectorLabel.comparisonMarketAccount,
        tip: '',
    },
    comparisonMarketMajorMarket: {
        label: selectorLabel.comparisonMarketMajorMarket,
        tip: '',
    },
    comparisonMarketStateline: {
        label: selectorLabel.comparisonMarketStateline,
        tip: '',
    },
    comparisonMarketRegion: {
        label: selectorLabel.comparisonMarketRegion,
        tip: '',
    },
    comparisonMarketStaticText: {
        label: selectorLabel.comparisonMarketStaticText,
        tip: '',
    },
    focusKPI: {
        label: selectorLabel.focusKPI,
        tip: selectorTipInfo.focusKPI,
    },
};
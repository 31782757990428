import './ReportRunConfigSummaryStep.scss';
import React, { ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import hashObject from 'object-hash';
import classnames from 'classnames';
import { ByzzerButton, WithUid, WizardContext, WizardStepMessageOptions } from '@byzzer/ui-components';
import ReportRunConfigSummary from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigSummaryStep/ReportRunConfigSummary';
// import ReportRunConfigSummary from "@/components/ConfigurationEditor/RunConfigSummaryStep/ReportRunConfigSummary"; // todo: see if this or the one above is needed.  merge conflict
import { ReportRunConfig } from '@/types/ReportRun';
import { useTenantApi } from '@/hooks/useTenantApi';
import { ReportRunConfigWizardContext } from '../ReportRunConfigWizard/ReportRunConfigWizardContext';
import { WizardActions, WizardContent, WizardHeader, WizardStep } from '@byzzer/ui-components';
import {
    AllRunConfigOptions,
    ConfigOptionsType,
    DimensionRunConfigOptions,
    MarketRunConfigOptions,
    ProductRunConfigOptions,
    RunConfigOptions,
    ShopperSegmentRunConfigOptions,
    TimeRunPeriodConfigOptions,
} from '@/types/RunConfigOptions';
import { ReportRunValidationError, useReportRunService } from '@/services/reportRun.service';
import { ErrorWithCodeResponse, ReportRunValidationResult } from '@/types/ApiTypes';
import { ReportValidationResultMessages } from '@/constants/report.constants';
import { useMarketContext } from '@/contexts/MarketContext';
import { useUser } from '@/contexts/UserContext';
import { useMarketService } from '@/services/market.service';

export type RunConfigSummaryStepProps = {
    title?: ReactNode;
    className?: string;
    onNext?: () => void;
    onEnableScheduleReport?: () => void;
    enabled?: boolean;
    sku?: string;
    busy?: boolean;
    busyNextText?: string;
    runConfigOptions?: RunConfigOptions[];
    id?: ConfigOptionsType;
    exclude?: boolean;
    isEditScheduleMode?: boolean;
    scheduledRunConfig?: ReportRunConfig;
};

const baseClassName = 'run-config-summary-step';

export const ReportRunConfigSummaryStep = React.forwardRef<WithUid, RunConfigSummaryStepProps>(
    (
        {
            className,
            onNext,
            onEnableScheduleReport,
            title = 'Review And Run',
            sku,
            enabled,
            runConfigOptions,
            busy,
            busyNextText,
            id,
            exclude,
            isEditScheduleMode,
            scheduledRunConfig,
            ...props
        },
        ref
    ) => {
        const { validateRunConfig, validateOshRunConfig } = useTenantApi();
        const stepRef = useRef<any>();
        const {
            company,
            accessibleMasterCompanies,
            customCharacteristics,
            features: { enableLimitedMarketSubscription },
        } = useUser();
        const { activeUid } = useContext(WizardContext);
        const { value: runConfig, onChange: onContextChange } = useContext(ReportRunConfigWizardContext);
        const { allMarkets } = useMarketContext();
        const { validateRunConfigForSku } = useReportRunService();
        const { getCachedMarketNodeByName } = useMarketService();
        const [validating, setValidating] = useState(false);
        const [validationResult, setValidationResult] = useState<ReportRunValidationResult | null>(null);
        const [validationMessage, setValidationMessage] = useState<WizardStepMessageOptions | undefined>();
        const lastValidatedConfig = useRef<string>('');
        const { datatype } = runConfig;

        const hasRunConfigChanged = (): boolean => {            
            if (isEditScheduleMode && scheduledRunConfig) {
                const initialValueHash = hashObject(scheduledRunConfig!, { excludeKeys: (key) => key === 'disabledMarketNode' });
                const valueHash = hashObject(runConfig!, { excludeKeys: (key) => key === 'disabledMarketNode' });
                return initialValueHash !== valueHash; // return true if the values have changed
            }
        
            return true; // in other modes, we assume that values have changed
        };

        const getDisabledTip = (): string => {
            if (validating) return 'Validating your selections';
            if (busy) return 'Your report is generating';
            if (!hasRunConfigChanged()) {
                return 'Please make any change in existing config to modify this schedule.';
            }
            return 'See errors above.';
        };

        const disableNext: boolean | undefined = validating || !validationResult?.canRun || busy || !hasRunConfigChanged();
        
        const nextText: string = isEditScheduleMode
            ? 'Apply'
            : !busy
            ? 'Generate Report'
            : busyNextText ?? 'Running...';
        const nextIconType: string | undefined =
            validating || busy ? 'busy' : validationResult?.canRun && hasRunConfigChanged() ? 'ok' : 'error';

        // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
        useImperativeHandle(ref, () => stepRef.current, []);
        /**
         * api call to fetch if data set is present or not
         * returns boolean
         */
        const checkIfReportDataIsValid = async () => {
            if (!runConfig) return;
            const comparableRunConfig = JSON.stringify(runConfig);

            // don't bother revalidating the config if it hasn't changed
            if (lastValidatedConfig.current === comparableRunConfig) return;

            lastValidatedConfig.current = comparableRunConfig;

            // before bothering to hit the server, make sure all of the required value are set.
            const validationError: boolean | ReportRunValidationError = validateRunConfigForSku(
                sku,
                runConfig,
                allMarkets,
                customCharacteristics
            );

            if (validationError && typeof validationError === 'object') {
                // TODO: Keeping the older error messages for Omni because of time constraint and different handling, will need future ticket to work on it
                switch (datatype) {
                    case 'omni':
                        if (validationError?.omniError) {
                            generateErrorMessage(ReportValidationResultMessages.default);
                        }
                        break;
                    case 'osh':
                        break;
                    default:
                        handleValidationError(validationError); // Handle errors accordingly
                        break;
                }
            }

            try {
                setValidating(true);
                setValidationMessage(undefined);
                
                let validationResult: ReportRunValidationResult;

                if (datatype === 'osh') {
                    validationResult = await validateOshRunConfig(
                        'subscription',
                        sku as string,
                        runConfig as ReportRunConfig
                    );
                } else {
                    validationResult = await validateRunConfig(
                        'subscription',
                        sku as string,
                        runConfig as ReportRunConfig
                    );
                }

                if (validationResult.hasOwnProperty('range')) {
                    switch (validationResult.range) {
                        case '<75':
                            setValidationMessage({
                                type: 'error',
                                content: (
                                    <>
                                        <p>Your selections are not releasable due to low sample.</p>
                                        <p>
                                            Choose a larger product set, market, or period in order to run this report.
                                        </p>
                                    </>
                                ),
                            });
                            break;
                        case '>75':
                            setValidationMessage({
                                type: 'warning',
                                content: (
                                    <>
                                        <p>
                                            All of your selections are releasable, but some have low sample. If you see
                                            a * in the report, use that value directionally.
                                        </p>
                                    </>
                                ),
                            });
                            break;
                        case '75-150':
                            setValidationMessage({
                                type: 'warning',
                                content: (
                                    <>
                                        <p>
                                            Your selections are releasable but with low sample, between 75-150
                                            occasions. Use directionally.
                                        </p>
                                    </>
                                ),
                            });
                            break;
                        case '<75>75':
                            setValidationMessage({
                                type: 'warning',
                                content: (
                                    <>
                                        <p>
                                            Some of your selections are not releasable due to low sample. If you run
                                            this report, some values that you selected may not be present.
                                        </p>
                                    </>
                                ),
                            });
                            break;
                        case '>150': // for some CPS reports, perhaps others
                        default:
                            setValidationMessage(undefined);
                            break;
                    }

                    // This is under BYZ-13828 when some selections are non releasable then we have to show this warning message
                    // There could be a possibility that range includes '>75', '75-150', '>150' but brandsWithTripsLessThan75 or mrktsWithTripsLessThan75 is not present
                    if (
                        ['>75', '75-150', '>150'].includes(validationResult.range!) &&
                        (validationResult?.brandsWithTripsLessThan75?.length ||
                            validationResult?.mrktsWithTripsLessThan75?.length)
                    ) {
                        setValidationMessage({
                            type: 'warning',
                            content: (
                                <>
                                    <p>
                                        Some of your selections are not releasable due to low sample. If you run this
                                        report, some values that you selected may not be present.
                                    </p>
                                </>
                            ),
                        });
                    }
                } else if (!validationResult.canRun) {
                    setValidationMessage({
                        type: 'error',
                        content: (
                            <>
                                <p>
                                    {datatype === 'omni'
                                        ? "There is no data or not enough sample for the selections you've made."
                                        : "There is no data for the selections you've made."}
                                </p>
                                <p>Please try different selections in order to run the report.</p>
                            </>
                        ),
                    });
                } else {
                    setValidationMessage(undefined);
                }
                setValidationResult(validationResult); //checkReportValidation(validateReportData));
            } catch (error: unknown) {
                handleErrorFromResponse(error);
            } finally {
                setValidating(false);
            }
        };

        useEffect(() => {
            if (activeUid === stepRef.current?.uid) {
                checkIfReportDataIsValid();
            }
        }, [activeUid]);

        const handleNext = (): boolean => {
            onNext?.();
            return false; // Making it false because there is one more step after this so, don't want to navigate on clicking Generate Report or Apply button
        };

        // Function to return error message based on market validation
        const generateMarketError = (): string => {
            const matchedMarkets = allMarkets.filter((mrkt) =>
                runConfig?.markets?.some((runMarket) => runMarket.key === mrkt.key)
            );

            if (runConfig?.markets?.length === 1) {
                const cachedMarket = getCachedMarketNodeByName(matchedMarkets[0].name);

                // Accessible market case
                let isMasterCompanyNotAccessible =
                    enableLimitedMarketSubscription &&
                    !accessibleMasterCompanies.includes(matchedMarkets[0].masterCompany!);
                if (isMasterCompanyNotAccessible) {
                    return ReportValidationResultMessages.market_not_accessible;
                }

                // Prior approval case
                if (cachedMarket?.hasApproval === false) {
                    return ReportValidationResultMessages.not_prior_approval;
                }

                // Premium market removal case
                let unPurchased =
                    cachedMarket?.isPremium === true &&
                    !company?.purchasedMarketKeys?.includes(cachedMarket?.key as string);
                if (unPurchased) {
                    return ReportValidationResultMessages.not_rev_share;
                }
            }

            // Multi market error scenario
            return ReportValidationResultMessages.market_not_found_multi;
        };

        const generateCategoryError = (): string => {
            if (runConfig?.metadata?.excludedCategories?.length > 0) {
                return runConfig?.categories?.length! > 0
                    ? ReportValidationResultMessages.category_subscription_not_found_multi
                    : ReportValidationResultMessages.category_subscription_not_found;
            }

            return ReportValidationResultMessages.category_subscription_not_found;
        };

        const handleValidationError = (validationError: ReportRunValidationError) => {
            const { categoryError, marketError, marketNotFoundError, customCharError } = validationError;

            if (categoryError) {
                return generateErrorMessage(generateCategoryError());
            }
            if (marketNotFoundError) {
                return generateErrorMessage(
                    runConfig?.markets?.length! > 1
                        ? ReportValidationResultMessages.market_not_found_multi
                        : ReportValidationResultMessages.market_not_found
                );
            }
            if (marketError) {
                return generateErrorMessage(generateMarketError());
            }
            if (customCharError) {
                return generateErrorMessage(ReportValidationResultMessages.custom_char_not_found);
            }

            // Default error message if no specific errors are found
            return generateErrorMessage(ReportValidationResultMessages.default);
        };

        const generateErrorMessage = (content: string) => {
            setValidationMessage({
                type: 'error',
                content,
            });
            setValidationResult({ canRun: false });
        };

        const handleErrorFromResponse = (error: unknown) => {
            const typedError = error as ErrorWithCodeResponse;
            if (
                typedError.code === ReportValidationResultMessages.category_subscription_not_found &&
                runConfig?.categories?.length! > 0
            ) {
                typedError.code = ReportValidationResultMessages.category_subscription_not_found_multi;
            }

            if (
                [
                    ReportValidationResultMessages.not_prior_approval,
                    ReportValidationResultMessages.not_rev_share,
                    ReportValidationResultMessages.market_not_accessible,
                    ReportValidationResultMessages.market_not_found,
                ].includes(typedError.code!)
            ) {
                typedError.code = ReportValidationResultMessages.market_not_found_multi;
            }

            setValidationMessage({
                type: 'error',
                content: <p>{ReportValidationResultMessages[typedError.code ?? 'default']}</p>,
            });
        };

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                byzRef={stepRef}
                enabled={enabled}
                message={validationMessage}
                title={title}
                id={id}
                exclude={exclude}
            >
                <WizardHeader>
                    <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
                </WizardHeader>
                <WizardContent>
                    <ReportRunConfigSummary runConfig={runConfig} validationResult={validationResult!} />
                </WizardContent>
                <WizardActions
                    disableNext={disableNext}
                    className={'report-run-config-wizard__step-actions'}
                    nextDisabledTip={getDisabledTip()}
                    nextIconType={nextIconType}
                    nextText={nextText}
                    beforeNext={handleNext}
                >
                    {/* Need to add omni as well once W/E logic is implemented for Omni report schedule */}
                    {['relative'].includes(runConfig.timePeriod?.type) && !isEditScheduleMode && (
                        <ByzzerButton
                            disabled={disableNext}
                            type='negative'
                            disabledTip={getDisabledTip()}
                            onClick={onEnableScheduleReport}
                        >
                            Schedule For Later
                        </ByzzerButton>
                    )}
                </WizardActions>
            </WizardStep>
        );
    }
);

export default ReportRunConfigSummaryStep;
